import React from 'react';
import { StorageContextType } from './types';

const StorageContext = React.createContext<StorageContextType>(null!);
const CookieManagementContext = React.createContext(false);

const Contexts = {
  StorageContext,
  CookieManagementContext,
};

export default Contexts;
