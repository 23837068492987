'use client';

import { useEffect } from 'react';
import { setCookie } from 'cookies-next';
import { useParams } from 'next/navigation';
import { useTranslation } from '@/i18n/client';
import { Locale } from '../../i18n-config';
import { fallbackLng, locales } from '@/i18n/settings';

const LanguageInitiator = ({ children }: { children: React.ReactNode }) => {
  const params = useParams();
  const { i18n } = useTranslation(params?.lang as Locale);

  useEffect(() => {
    i18n.on('languageChanged', (lng) => {
      if (locales.includes(lng as any)) setCookie('NEXT_LOCALE', lng);
      else {
        setCookie('NEXT_LOCALE', fallbackLng);
        i18n.changeLanguage(fallbackLng);
      }
    });
  }, [i18n]);

  return children;
};

export default LanguageInitiator;
