'use client';

import { Container } from 'react-bootstrap';
import { usePathname } from 'next/navigation';
import LanguageChangeDropdown from '../common/LanguageChangeDropdown';

type Props = {
  theme?: string;
  className?: string;
};

function SiteFooterComponent({ theme, className }: Props) {
  const pathname = usePathname();

  if (pathname.includes('chat/')) return null;

  return (
    <footer className={`p-2 tw-mt-[-1px] ${theme == 'light' ? 'bg-eightColor' : 'bg-firstColor'} ${className}`}>
      <Container fluid={'xl'}>
        <div className="d-flex justify-content-between align-items-center">
          <span className={`${theme == 'light' ? 'color-fifthColor' : 'color-sevenColor'} tw-text-xs`}>
            <small>© LouiseBot 2024 - {process.env.NEXT_PUBLIC_APP_VERSION as string}</small>
          </span>
          {/* <LanguageChangeDropdown id="footerLocaleChanger" /> */}
        </div>
      </Container>
    </footer>
  );
}

export default SiteFooterComponent;
