'use client';

import Utils from '@/utils';
import './style.css';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import Image from 'next/image';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

type ConsentType = {
  ad_storage: 'granted' | 'denied';
  analytics_storage: 'granted' | 'denied';
  personalization_storage: 'granted' | 'denied';
  functionality_storage: 'granted' | 'denied';
  security_storage: 'granted' | 'denied';
};

type checkBoxesType = {
  necessary: boolean;
  analytics: boolean;
  preferences: boolean;
  marketing: boolean;
};

const ConsentManager = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [open, setOpen] = useState(false);

  const consentRef = useRef<HTMLDivElement | null>(null);

  const [checkBoxes, setCheckBoxes] = useState<checkBoxesType>({
    necessary: true,
    analytics: true,
    preferences: true,
    marketing: false,
  });

  const handlePersistConsents = useCallback((consent: ConsentType) => {
    if (typeof Storage !== 'undefined' && window.localStorage && typeof localStorage !== 'undefined') {
      let currentConsent = localStorage.getItem('consentMode');

      if (!currentConsent && (window as unknown as Window & typeof globalThis)) {
        window.gtag('consent', 'update', consent);

        localStorage.setItem('consentMode', JSON.stringify(consent));

        if (searchParams) Utils.utmManager(searchParams);

        document.body.classList.remove('consent-manager-open');

        setOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConsents = useCallback(
    (type: 'all' | 'selection' | 'none', checkBoxes: any) => {
      const enumConsent: { all: ConsentType; selection: ConsentType; none: ConsentType } = {
        all: {
          ad_storage: 'granted',
          analytics_storage: 'granted',
          personalization_storage: 'granted',
          functionality_storage: 'granted',
          security_storage: 'granted',
        },
        selection: {
          ad_storage: checkBoxes.marketing ? 'granted' : 'denied',
          analytics_storage: checkBoxes.analytics ? 'granted' : 'denied',
          personalization_storage: checkBoxes.preferences ? 'granted' : 'denied',
          functionality_storage: 'granted',
          security_storage: 'granted',
        },
        none: {
          ad_storage: 'denied',
          analytics_storage: 'denied',
          personalization_storage: 'denied',
          functionality_storage: 'granted',
          security_storage: 'granted',
        },
      };

      handlePersistConsents(enumConsent[type]);
    },

    [handlePersistConsents]
  );

  useEffect(() => {
    const handleOutside = (e: MouseEvent) => {
      const button = document.querySelector('button');

      if (e.target !== button && consentRef.current && !consentRef.current.contains(e.target as Node))
        handleConsents('all', checkBoxes);
    };

    if (
      typeof Storage !== 'undefined' &&
      window.localStorage &&
      typeof localStorage !== 'undefined' &&
      !pathname.includes('/chat/')
    ) {
      let currentConsent = localStorage.getItem('consentMode');

      if (!currentConsent)
        setTimeout(() => {
          setOpen(true);

          document.body.classList.add('consent-manager-open');
        }, 3000);

      document.addEventListener('click', handleOutside);
    }

    return () => {
      if (typeof Storage !== 'undefined' && window.localStorage && typeof localStorage !== 'undefined') {
        document.body.classList.remove('consent-manager-open');

        setOpen(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleConsents]);

  if (!open || pathname.includes('/chat/')) return null;

  return (
    <div className={`consent-manager ${open ? 'open' : ''}`} ref={consentRef}>
      <div className="tw-flex tw-justify-center tw-mb-3">
        <Image
          src={'https://cdn.pinkswan.ch/application/PIL/24050806320483_American_Cookie_-_1372x1347.png'}
          alt="cookie"
          width={60}
          height={60}
        />
      </div>

      <p className="stw-elf-stretch tw-text-center tw-text-gray-700 tw-text-lg tw-font-semibold tw-font-['Figtree'] tw-leading-7 tw-md:w-3/4 tw-mx-auto">
        We use cookies to enhance your experience and analyze usage to improve our site.
      </p>
      <div className="buttons">
        <button
          className="tw-px-[17px] tw-py-[9px] tw-bg-blue-600 tw-rounded-md tw-shadow tw-justify-center tw-items-center tw-flex tw-text-white tw-text-sm tw-font-medium tw-font-['Figtree'] tw-leading-tight"
          onClick={() => handleConsents('all', checkBoxes)}
        >
          Accept All
        </button>
        <button
          className="tw-px-[17px] tw-py-[9px] tw-bg-blue-100 tw-rounded-md tw-shadow tw-justify-center tw-items-center tw-flex tw-text-blue-800 tw-text-sm tw-font-medium tw-font-['Figtree'] tw-leading-tight"
          onClick={() => handleConsents('selection', checkBoxes)}
        >
          Accept Selection
        </button>
        <button
          className="tw-px-[17px] tw-py-[9px] tw-rounded-md tw-shadow tw-border tw-border-gray-600 tw-justify-center tw-items-center tw-flex tw-text-[#374151] tw-text-sm tw-font-medium tw-font-['Figtree'] tw-leading-tight"
          onClick={() => handleConsents('none', checkBoxes)}
        >
          Reject All
        </button>
      </div>
      <div className="checklists">
        <div>
          <input type="checkbox" defaultChecked={checkBoxes.necessary} disabled />
          <label>
            <span>Necessary</span>
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            defaultChecked={checkBoxes.analytics}
            onChange={(e) => setCheckBoxes((prev) => ({ ...prev, analytics: e.target.checked }))}
            id="analytics"
          />
          <label htmlFor="analytics">
            <span>Analytics</span>
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            defaultChecked={checkBoxes.preferences}
            onChange={(e) => setCheckBoxes((prev) => ({ ...prev, preferences: e.target.checked }))}
            id="preferences"
          />
          <label htmlFor="preferences">
            <span>Preferences</span>
          </label>
        </div>
        <div>
          <input
            type="checkbox"
            defaultChecked={checkBoxes.marketing}
            onChange={(e) => setCheckBoxes((prev) => ({ ...prev, marketing: e.target.checked }))}
            id="marketing"
          />
          <label htmlFor="marketing">
            <span>Marketing</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default ConsentManager;
