'use client';

import React from 'react';
import { useRouter } from 'next/navigation';
import Link from 'next/link';

type Props = {
  children: any;
  href: string;
  className?: string;
  onClick?: () => void;
  target?: string;
};

function LinkTo({ children, href, className, target, onClick }: Props) {
  const router = useRouter();

  const handelClick = () => {
    onClick?.();
    router.push(href);
  };
  return (
    <button onClick={handelClick} className={'tw-border-none tw-inline-flex ' + className}>
      {children}
    </button>
  );
}

export default LinkTo;
